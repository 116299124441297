var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ar-main-container',{key:_vm.$route.params.id},[_c('header',{staticClass:"layout_header"},[_c('h2',{staticClass:"layout_header-title"},[_vm._v(" "+_vm._s(_vm.currentProject.name)+" "),_c('router-link',{staticClass:"card__link button",attrs:{"to":{
                name: 'projects-card',
                params: {
                    id: _vm.currentProject.id,
                },
            },"tag":"a"},domProps:{"innerHTML":_vm._s('Карта проєкту')}})],1)]),_c('ar-main-sidebar',[_c('main-filters',{attrs:{"result-count":_vm.resultCount}})],1),_c('div',{staticClass:"singleProject"},[_c('header',{staticClass:"singleProject_header"},[_c('ar-search',{attrs:{"is-redirect":false}}),_c('ar-select-sort')],1),(!_vm.isSearching && !_vm.isFiltering)?_c('section',_vm._l((_vm.docsByTypeFiltered),function(doc,index){return _c('article',{key:index,staticClass:"singleProject_body"},[_c('h2',{staticClass:"singleProject-title"},[_vm._v(_vm._s(doc.name))]),_c('full-docs',{attrs:{"list-data":doc.documents,"no-project-name":true,"no-material-name":true}}),(doc.documents.length > 0)?_c('footer',{staticClass:"singleProject_footer"},[(
                            doc.documents.length < doc._documentsMeta.count
                        )?_c('ar-download-more',{attrs:{"loaded-count":doc.documents.length,"total-count":doc._documentsMeta.count},on:{"load-more":function($event){return _vm.loadMore(doc.id)}}}):_vm._e()],1):_vm._e()],1)}),0):(_vm.isSearching)?_c('section',{staticClass:"singleProject_body"},[(
                    _vm.SEARCH_RESULT.inTitle.count === 0 &&
                    _vm.SEARCH_RESULT.inText.count === 0 &&
                    _vm.SEARCH_RESULT.inBook.count === 0
                )?_c('search-result-empty'):_vm._l((_vm.articles),function(article,propName){return _c('article',{key:propName,staticClass:"searchPage_body_article"},[_c('h3',{staticClass:"searchPage_body_article-title"},[_vm._v(" "+_vm._s(article.title)+" ")]),_c('full-docs',{attrs:{"list-data":article.data.list,"is-read":propName === 'inText'}}),(article.data.count !== 0)?_c('footer',{staticClass:"searchPage_footer"},[_c('ar-download-more',{attrs:{"loaded-count":article.data.list.length,"total-count":article.data.count},on:{"load-more":function($event){return _vm.LOAD_SSF_RESULT({
                                isBook: false,
                                isLoadMore: true,
                                searchedListPropName: propName,
                            })}}})],1):_vm._e()],1)})],2):(_vm.isFiltering)?_c('section',{staticClass:"singleProject_body"},[(_vm.FILTER_RESULT.count === 0)?_c('search-result-empty'):_c('article',{staticClass:"singleProject_body_article"},[_c('full-docs',{attrs:{"list-data":_vm.FILTER_RESULT.list}}),(_vm.FILTER_RESULT.count !== 0)?_c('footer',{staticClass:"singleProject_footer"},[_c('ar-download-more',{attrs:{"loaded-count":_vm.FILTER_RESULT.list.length,"total-count":_vm.FILTER_RESULT.count},on:{"load-more":function($event){return _vm.LOAD_SSF_RESULT({
                                isBook: false,
                                isLoadMore: true,
                            })}}})],1):_vm._e()],1)],1):_vm._e()]),_c('books-section')],1)}
var staticRenderFns = []

export { render, staticRenderFns }