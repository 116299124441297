



















import { Vue, Component } from 'vue-property-decorator';
import BookCards from '@/components/block/book-cards.vue';
import { FullDocument } from '@/types/front-data-types/full-docs';
import { clone } from '@/helpers/objects';
import graphql from '@/plugins/graphql';
import { DocumentsByFiltersRequest } from '@/types/requests/documents';
import GetBookList from '@/graphql/documents/GetBookList.gql';
import ArButton from '@/components/form/ar-button.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';

@Component({
    name: 'books-section',
    components: {
        BookCards,
        ArButton,
        ArDownloadMore,
    },
})
export default class BooksSection extends Vue {
    private books: FullDocument[] = [];
    private perPage = 4;
    private totalCount = 0;

    public async loadBooks(): Promise<void> {
        const books = await graphql<DocumentsByFiltersRequest>(GetBookList, {
            perPage: this.perPage,
            skip: 0,
        });
        if (books && books.allDocuments) {
            this.books = books.allDocuments;
        }
        if (books && books._allDocumentsMeta) {
            this.totalCount = books._allDocumentsMeta.count;
        }
    }

    async mounted(): Promise<void> {
        await this.loadBooks();
        console.log('Books section: ', clone(this.books));
    }
}
