























































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import ArMainContainer from '@/components/section/ar-main-container.vue';
import ArMainSidebar from '@/components/section/ar-main-sidebar.vue';

import ArButton from '@/components/form/ar-button.vue';
import ArSearch from '@/components/form/ar-search.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';
import ArSelectSort from '@/components/form/ar-select-sort.vue';

import MainFilters from '@/components/block/main-filters.vue';
import FullDocs from '@/components/block/full-docs.vue';
import BookCards from '@/components/block/book-cards.vue';
import SearchResultEmpty from '@/components/block/search-result-empty.vue';

import projectById from '@/graphql/projects/projecById.graphql';
import documentsByMaterialAndProject from '@/graphql/types/documentsByMaterialAndProject.graphql';
import GetDocumentsByProjectAndMaterial from '@/graphql/documents/GetDocumentsByProjectAndMaterial.graphql';
import {
    defaultProject,
    Project,
    ProjectDocsByMaterial,
    SingleProjectResp,
} from '@/types/requests/projects';
import { clone } from '@/helpers/objects';
import graphql from '@/plugins/graphql';
import { DocumentsByFiltersRequest } from '@/types/requests/documents';
import BooksSection from '@/components/block/books-section.vue';
import { Action, Getter } from 'vuex-class';
import { WhereIdObject } from '@/types/requests/filters';
import { Filter } from '@/types/front-data-types/filter';
import { NavigationGuardNext, Route } from 'vue-router';
import { ApiDocumentMaterial } from '@/types/requests/documentMaterial';

Component.registerHooks(['beforeRouteLeave']);
@Component({
    name: 'SingleProject',
    components: {
        BooksSection,
        ArMainContainer,
        ArMainSidebar,
        ArButton,
        ArSearch,
        ArDownloadMore,
        ArSelectSort,
        MainFilters,
        SearchResultEmpty,
        FullDocs,
        BookCards,
    },
})
export default class SingleProject extends Vue {
    private currentProject: Project = clone(defaultProject);
    private docsByType: ApiDocumentMaterial[] = [];
    private perPage = 5;

    // region VUEX
    @Getter('Search/SEARCH_VALUE')
    private SEARCH_VALUE: string;
    @Getter('Search/IS_SEARCH_TAG')
    private IS_SEARCH_TAG: string;
    @Getter('Sort/SORT_VALUE')
    private SORT_VALUE: string;

    @Getter('SSF/FILTER_RESULT')
    private FILTER_RESULT;
    @Getter('SSF/SEARCH_RESULT')
    private SEARCH_RESULT;

    @Getter('Filters/SELECTED_FILTERS')
    private SELECTED_FILTERS: Filter;
    @Getter('Filters/SELECTED_FILTERS_TAGS')
    private SELECTED_FILTERS_TAGS;

    @Action('Filters/CLEAR_SELECTED_FILTERS')
    CLEAR_SELECTED_FILTERS: () => void;
    @Action('Search/CLEAR_SEARCH_DATA')
    CLEAR_SEARCH_DATA: () => void;
    @Action('Sort/CLEAR_SORT_DATA')
    CLEAR_SORT_DATA: () => void;

    @Action('Filters/LOAD_CURRENT_PROJECT_ID')
    LOAD_CURRENT_PROJECT_ID: (payload: WhereIdObject | undefined) => void;
    @Action('SSF/LOAD_SSF_RESULT')
    public LOAD_SSF_RESULT!: (payload: {
        isBook: boolean;
        isLoadMore: boolean;
        searchedListPropName?: string;
    }) => Promise<void>;
    // endregion

    // region WATCHERS
    @Watch('SELECTED_FILTERS', { deep: true })
    async onFiltersChanged(): Promise<void> {
        await this.LOAD_SSF_RESULT({ isBook: false, isLoadMore: false });
    }
    @Watch('SEARCH_VALUE')
    async onSearchValueChanged(): Promise<void> {
        await this.LOAD_SSF_RESULT({ isBook: false, isLoadMore: false });
    }

    @Watch('SORT_VALUE')
    async onSortValueChanged(): Promise<void> {
        await this.LOAD_SSF_RESULT({ isBook: false, isLoadMore: false });
    }
    // endregion

    // region GETTERS
    get articles(): Record<string, unknown> {
        if (this.SORT_VALUE === 'Спочатку — в назві документу') {
            return {
                inTitle: {
                    title: 'В назві документу',
                    data: this.SEARCH_RESULT.inTitle,
                },
                inText: {
                    title: 'В тексті документу',
                    data: this.SEARCH_RESULT.inText,
                },
                inBook: {
                    title: 'У назві чи описі книги',
                    data: this.SEARCH_RESULT.inBook,
                },
            };
        } else if (this.SORT_VALUE === 'Спочатку — в тексті документу') {
            return {
                inText: {
                    title: 'В тексті документу',
                    data: this.SEARCH_RESULT.inText,
                },
                inTitle: {
                    title: 'В назві документу',
                    data: this.SEARCH_RESULT.inTitle,
                },
                inBook: {
                    title: 'У назві чи описі книги',
                    data: this.SEARCH_RESULT.inBook,
                },
            };
        } else if (this.SORT_VALUE === 'Спочатку — в назві чи описі книги') {
            return {
                inBook: {
                    title: 'У назві чи описі книги',
                    data: this.SEARCH_RESULT.inBook,
                },
                inText: {
                    title: 'В тексті документу',
                    data: this.SEARCH_RESULT.inText,
                },
                inTitle: {
                    title: 'В назві документу',
                    data: this.SEARCH_RESULT.inTitle,
                },
            };
        } else {
            return {
                inTitle: {
                    title: 'В назві документу',
                    data: this.SEARCH_RESULT.inTitle,
                },
                inText: {
                    title: 'В тексті документу',
                    data: this.SEARCH_RESULT.inText,
                },
                inBook: {
                    title: 'У назві чи описі книги',
                    data: this.SEARCH_RESULT.inBook,
                },
            };
        }
    }

    get resultCount(): number | null {
        return (
            Number(this.FILTER_RESULT.count) +
            Number(this.SEARCH_RESULT.inTitle.count) +
            Number(this.SEARCH_RESULT.inText.count) +
            Number(this.SEARCH_RESULT.inBook.count)
        );
    }
    private get isSearching(): boolean {
        return !!this.SEARCH_VALUE && !this.IS_SEARCH_TAG;
    }
    private get isFiltering(): boolean {
        return this.SELECTED_FILTERS_TAGS.length !== 0;
    }

    private get docsByTypeFiltered(): ApiDocumentMaterial[] {
        return this.docsByType.filter((material: ApiDocumentMaterial) => {
            return material.documents.length > 0;
        });
    }
    // endregion

    // region METHODS
    private getDocTypeById(
        docMaterialId: string | number
    ): ApiDocumentMaterial | undefined {
        return this.docsByType.find(
            (material) => material.id === docMaterialId
        );
    }

    private getSkipByDocType(docMaterialId: string | number) {
        const docType = this.getDocTypeById(docMaterialId);
        if (docType && docType.documents) {
            return docType.documents.length;
        }
        return 0;
    }

    private async loadMore(docMaterialId: string | number): Promise<void> {
        const skip = this.getSkipByDocType(docMaterialId);
        const newDocs = await graphql<DocumentsByFiltersRequest>(
            GetDocumentsByProjectAndMaterial,
            {
                projectId: this.currentProject.id,
                materialId: docMaterialId,
                skip: skip,
                perPage: this.perPage,
            }
        );
        if (newDocs && newDocs.allDocuments) {
            const doctype = this.getDocTypeById(docMaterialId);
            if (doctype) {
                const oldDocs = doctype.documents;
                doctype.documents = [...oldDocs, ...newDocs.allDocuments];
            }
        }
    }

    private async loadProject(): Promise<void> {
        const projectId = this.$route.params.id as string;
        const projectPromise = graphql<SingleProjectResp>(projectById, {
            id: projectId,
        });
        const projectDocsPromise = graphql<ProjectDocsByMaterial>(
            documentsByMaterialAndProject,
            {
                projectId,
                perPage: this.perPage,
            }
        );
        const resp: [
            SingleProjectResp | undefined,
            ProjectDocsByMaterial | undefined
        ] = await Promise.all([projectPromise, projectDocsPromise]);
        const pResp = resp[0];
        const dResp = resp[1];
        if (pResp && pResp.Project) {
            this.currentProject = pResp.Project;
        }
        if (dResp && dResp.allMaterials) {
            this.docsByType = dResp.allMaterials;
        }
    }
    // endregion

    // region LIFECYCLE HOOKS
    async created(): Promise<void> {
        this.CLEAR_SEARCH_DATA();
        this.CLEAR_SELECTED_FILTERS();
        this.CLEAR_SORT_DATA();
        await this.loadProject();
        this.LOAD_CURRENT_PROJECT_ID({ id: this.$route.params.id });
    }

    beforeRouteLeave(from: Route, to: Route, next: NavigationGuardNext): void {
        this.LOAD_CURRENT_PROJECT_ID(undefined);
        next();
    }
    // endregion
}
