import { ApiDocumentType } from '@/types/requests/documentType';
import { ApiDocumentMaterial } from '@/types/requests/documentMaterial';

export type Project = {
    id: string | number;
    name: string;
};

export const defaultProject: Project = {
    id: 0,
    name: '',
};

export type AllProjectsResp = {
    allProjects: Project[];
};

export type SingleProjectResp = {
    Project: Project;
};

export type ProjectDocsByType = {
    allDocumentTypes: ApiDocumentType[];
};

export type ProjectDocsByMaterial = {
    allMaterials: ApiDocumentMaterial[];
};
