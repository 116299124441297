



















































import { Component, Vue, Watch } from 'vue-property-decorator';

import ArMainContainer from '@/components/section/ar-main-container.vue';

import ArButton from '@/components/form/ar-button.vue';
import ArSearch from '@/components/form/ar-search.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';
import ArSelectSort from '@/components/form/ar-select-sort.vue';

import MainFilters from '@/components/block/main-filters.vue';
import FullDocs from '@/components/block/full-docs.vue';
import BookCards from '@/components/block/book-cards.vue';
import { FullDocumentListFrontData } from '@/types/front-data-types/full-docs';
import { clone } from '@/helpers/objects';
import { Action, Getter } from 'vuex-class';
import { defaultFilter, Filter } from '@/types/front-data-types/filter';
import { NavigationGuardNext, Route } from 'vue-router';

Component.registerHooks(['beforeRouteLeave']);
@Component({
    name: 'AllBooks',
    components: {
        ArMainContainer,

        ArButton,
        ArSearch,
        ArDownloadMore,
        ArSelectSort,

        MainFilters,
        FullDocs,
        BookCards,
    },
})
export default class AllBooks extends Vue {
    private columnCount = 3;

    // region VUEX
    @Getter('Filters/SELECTED_FILTERS')
    private SELECTED_FILTERS: Filter;
    @Getter('Search/SEARCH_VALUE')
    private SEARCH_VALUE: string;
    @Getter('Sort/SORT_VALUE')
    private SORT_VALUE: string;

    @Getter('SSF/FILTER_RESULT')
    private FILTER_RESULT;
    @Getter('SSF/SEARCH_RESULT')
    private SEARCH_RESULT;

    @Action('Filters/LOAD_CUSTOM_FILTERS')
    public LOAD_CUSTOM_FILTERS!: (payload: Filter) => void;
    @Action('SSF/LOAD_SSF_RESULT')
    public LOAD_SSF_RESULT!: (payload: {
        isBook: boolean;
        isLoadMore: boolean;
        searchedListPropName?: string;
    }) => Promise<void>;
    // endregion

    // region WATCHERS
    @Watch('SELECTED_FILTERS', { deep: true })
    async onFiltersChanged(): Promise<void> {
        await this.LOAD_SSF_RESULT({ isBook: true, isLoadMore: false });
    }
    @Watch('SEARCH_VALUE')
    async onSearchValueChanged(): Promise<void> {
        await this.LOAD_SSF_RESULT({ isBook: true, isLoadMore: false });
    }

    @Watch('SORT_VALUE')
    async onSortValueChanged(): Promise<void> {
        await this.LOAD_SSF_RESULT({ isBook: true, isLoadMore: false });
    }
    // endregion

    // region GETTERS
    get resultCount(): number | null {
        return (
            Number(this.FILTER_RESULT.count) +
            Number(this.SEARCH_RESULT.inTitle.count) +
            Number(this.SEARCH_RESULT.inText.count) +
            Number(this.SEARCH_RESULT.inBook.count)
        );
    }
    get SSFResult(): FullDocumentListFrontData {
        return this.SEARCH_VALUE
            ? this.SEARCH_RESULT.inTitle
            : this.FILTER_RESULT;
    }
    // endregion

    // region LIFECYCLE HOOKS
    async created(): Promise<void> {
        await this.LOAD_SSF_RESULT({ isBook: true, isLoadMore: false });

        const customFilter: Filter = clone(defaultFilter);
        delete customFilter.documentTypes;
        delete customFilter.materials;
        this.LOAD_CUSTOM_FILTERS(customFilter);
    }

    beforeRouteLeave(from: Route, to: Route, next: NavigationGuardNext): void {
        this.LOAD_CUSTOM_FILTERS(defaultFilter);
        next();
    }
    // endregion
}
